import gql from 'graphql-tag'


const listResponse = `
  id type code name remark
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  categories {id name}
`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($docType: String!, $q: FilterInput) {
  docs: listDoc${templateType} (docType: $docType, q: $q) {${listResponse}}
}`

export const WATCH_DOCS_CREATED = (templateType) => gql`subscription WATCH_DOCS_CREATED ($docType: String!) {
  docCreated: watchDoc${templateType}Created (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_UPDATED = (templateType) => gql`subscription WATCH_DOCS_UPDATED ($docType: String!) {
  docUpdated: watchDoc${templateType}Updated (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_DESTROYED = (templateType) => gql`subscription WATCH_DOCS_DESTROYED ($docType: String!) {
  docDestroyed: watchDoc${templateType}Destroyed (docType: $docType) {id}
}`

const detailResponse = `
  id type code name remark
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  categories {id name}
`

export const DETAIL_DOC = (templateType) => gql`query DOC ($docType: String!, $docId: Int!) {
  doc: detailDoc${templateType} (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const UPDATE_DOC = (templateType) => gql`mutation UPDATE_DOC ($docType: String!, $docId: Int!, $input: Doc${templateType}Input!) {
  doc: updateDoc${templateType} (docType: $docType, docId: $docId, input: $input) {id}
}`

export const WATCH_DOC_UPDATED = (templateType) => gql`subscription WATCH_DOC_UPDATED ($docType: String!, $docId: Int) {
  docUpdated: watchDoc${templateType}Updated (docType: $docType, docId: $docId) {${detailResponse}}
}`
