import gql from 'graphql-tag'


const detailResponse = `
  id type name
  qty price totalPrice
  withholdingType withholdingTopic
  withholdingName withholdingRemark
  withholdingRate withholdingPrice
  unitId unit {id name}
  ledgers {
    id docId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`
