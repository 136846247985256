var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[(!_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"card-text"},[_c('span',{staticClass:"mx-1 pointer float-right",on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('fa',{staticClass:"text-warning",attrs:{"icon":"pencil-alt"}})],1),_c('div',{},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.item.options && _vm.item.options.title)?_c('div',{staticClass:"text-info pre-line"},[_c('small',[_vm._v("ชื่อเอกสาร: "+_vm._s(_vm.item.options.title))])]):_vm._e(),(_vm.item.options && _vm.item.options.contact)?_c('div',{staticClass:"text-primary"},[_c('small',[_vm._v("ชื่อบริษัท: "+_vm._s(_vm.item.options.contact))])]):_vm._e(),(_vm.item.options && _vm.item.options.taxCode)?_c('div',{staticClass:"text-success"},[_c('small',[_vm._v("เลขประจำตัวผู้เสียภาษี: "+_vm._s(_vm.item.options.taxCode))])]):_vm._e(),(_vm.item.options && _vm.item.options.branch)?_c('div',{staticClass:"text-warning"},[_c('small',[_vm._v("สาขา: "+_vm._s(_vm.item.options.branch))])]):_vm._e(),(_vm.item.options && _vm.item.options.address)?_c('div',{staticClass:"pre-line"},[_c('small',[_vm._v("ที่อยู่บริษัท: "+_vm._s(_vm.item.options.address))])]):_vm._e()])]):_vm._e(),(_vm.toggle)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"ชื่อ","validations":[
            {text: 'required!', value: _vm.$v.formData.name.$dirty && !_vm.$v.formData.name.required}
          ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"ชื่อเอกสาร","validations":[
            {text: 'required!', value: _vm.$v.formData.options.title.$dirty && !_vm.$v.formData.options.title.required}
          ]},model:{value:(_vm.formData.options.title),callback:function ($$v) {_vm.$set(_vm.formData.options, "title", $$v)},expression:"formData.options.title"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"ชื่อบริษัท","validations":[
            {text: 'required!', value: _vm.$v.formData.options.contact.$dirty && !_vm.$v.formData.options.contact.required}
          ]},model:{value:(_vm.formData.options.contact),callback:function ($$v) {_vm.$set(_vm.formData.options, "contact", $$v)},expression:"formData.options.contact"}}),_c('sgv-input-text',{staticClass:"col-12 col-sm-6",attrs:{"label":"เลขประจำตัวผู้เสียภาษี","validations":[
            {text: 'required!', value: _vm.$v.formData.options.taxCode.$dirty && !_vm.$v.formData.options.taxCode.required}
          ]},model:{value:(_vm.formData.options.taxCode),callback:function ($$v) {_vm.$set(_vm.formData.options, "taxCode", $$v)},expression:"formData.options.taxCode"}}),_c('sgv-input-text',{staticClass:"col-12 col-sm-6",attrs:{"label":"สาขา","validations":[
            {text: 'required!', value: _vm.$v.formData.options.branch.$dirty && !_vm.$v.formData.options.branch.required}
          ]},model:{value:(_vm.formData.options.branch),callback:function ($$v) {_vm.$set(_vm.formData.options, "branch", $$v)},expression:"formData.options.branch"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"ที่อยู่บริษัท","validations":[
            {text: 'required!', value: _vm.$v.formData.options.address.$dirty && !_vm.$v.formData.options.address.required}
          ]},model:{value:(_vm.formData.options.address),callback:function ($$v) {_vm.$set(_vm.formData.options, "address", $$v)},expression:"formData.options.address"}})],1),(_vm.isForm)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" เพิ่ม ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.updateData}},[_vm._v(" แก้ไข ")]):_vm._e(),(_vm.toggle)?_c('button',{staticClass:"btn btn-primary ml-2",attrs:{"type":"button"},on:{"click":_vm.cancelData}},[_vm._v(" ยกเลิก ")]):_vm._e(),(!_vm.isForm)?_c('button',{staticClass:"btn btn-danger ml-2 float-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyData(_vm.item.id)}}},[_vm._v(" ลบ ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }